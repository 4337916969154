import { Reducer } from 'react'

import { ClosedRange, Range, SearchValue, SupplySearchParams, closedRange, openRange } from './search.model'
import { createQuerySearch } from './search.utils'

export const setQuery = (query: SearchValue) =>
    ({
        type: 'search/setQuery',
        query,
    }) as const

export const setMinPrice = (minPrice: number) =>
    ({
        type: 'search/setMinPrice',
        minPrice,
    }) as const

export const setMaxPrice = (maxPrice: number) =>
    ({
        type: 'search/setMaxPrice',
        maxPrice,
    }) as const

export const setPrice = (price: Range) =>
    ({
        type: 'search/setPrice',
        price,
    }) as const

export const setMinSize = (minSize: number) =>
    ({
        type: 'search/setMinSize',
        minSize,
    }) as const

export const setMaxSize = (maxSize: number) =>
    ({
        type: 'search/setMaxSize',
        maxSize,
    }) as const

export const setSize = (size: Range) =>
    ({
        type: 'search/setSize',
        size,
    }) as const

export const clearSearch = () =>
    ({
        type: 'search/clear',
    }) as const

export type SupplySearchAction = ReturnType<
    | typeof setQuery
    | typeof setMinPrice
    | typeof setMaxPrice
    | typeof setPrice
    | typeof setMinSize
    | typeof setMaxSize
    | typeof setSize
    | typeof clearSearch
>

type ReducerOptions = {
    defaultSize: ClosedRange
    defaultPrice: ClosedRange
    maxPrice: number
    maxSize: number
}

export const initialSearchParams: SupplySearchParams = Object.freeze({
    query: createQuerySearch(''),
    size: undefined,
    price: undefined,
})

export const defaultPrice = closedRange(0, 50_000)
export const defaultSize = closedRange(0, 350)
export const maxPrice = 100_000
export const maxSize = 700

export const supplySearchReducer =
    (options: ReducerOptions): Reducer<SupplySearchParams, SupplySearchAction> =>
    (state, action) => {
        switch (action.type) {
            case 'search/setQuery':
                return { ...state, query: action.query }

            case 'search/setMinPrice':
                return {
                    ...state,
                    price: (() => {
                        if (state.price === undefined) {
                            return closedRange(action.minPrice, options.defaultPrice.max)
                        }

                        if (state.price.type === 'closed') {
                            return closedRange(action.minPrice, state.price.max)
                        }

                        return openRange(action.minPrice)
                    })(),
                }

            case 'search/setMaxPrice':
                return {
                    ...state,
                    price: (() => {
                        const min = state.price ? state.price.min : options.defaultPrice.min

                        if (action.maxPrice < options.maxPrice) {
                            return closedRange(min, action.maxPrice)
                        }

                        return openRange(min)
                    })(),
                }

            case 'search/setPrice':
                return {
                    ...state,
                    price: (() => {
                        if (action.price.type === 'closed' && action.price.max < options.maxPrice) {
                            return closedRange(action.price.min, action.price.max)
                        }

                        return openRange(action.price.min)
                    })(),
                }

            case 'search/setMinSize':
                return {
                    ...state,
                    size: (() => {
                        if (state.size === undefined) {
                            return closedRange(action.minSize, options.defaultSize.max)
                        }

                        if (state.size.type === 'closed') {
                            return closedRange(action.minSize, state.size.max)
                        }

                        return openRange(action.minSize)
                    })(),
                }

            case 'search/setMaxSize':
                return {
                    ...state,
                    size: (() => {
                        const min = state.size ? state.size.min : options.defaultSize.min

                        if (action.maxSize < options.maxSize) {
                            return closedRange(min, action.maxSize)
                        }

                        return openRange(min)
                    })(),
                }

            case 'search/setSize':
                return {
                    ...state,
                    size: (() => {
                        if (action.size.type === 'closed' && action.size.max < options.maxSize) {
                            return closedRange(action.size.min, action.size.max)
                        }

                        return openRange(action.size.min)
                    })(),
                }

            case 'search/clear':
                return initialSearchParams

            default:
                return state
        }
    }
