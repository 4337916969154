import { Bars3Icon } from '@heroicons/react/24/outline'
import Link from 'next/link'
import { ComponentProps, useState } from 'react'

import Logo from '@/components/_shared/Logo'
import { SideMenu } from '@/components/_shared/SideMenu'
import AuthIcon from '@/components/auth/AuthIcon'
import AuthModal from '@/components/auth/AuthModal'

import { trackAnnounceProperty, trackBlog, trackHelp } from './Nav'

const BarButton = ({ children, ...props }: ComponentProps<'button'>) => (
    <button
        type="button"
        className="inline-flex items-center justify-center gap-4 rounded-lg focus:outline-none md:hidden"
        aria-controls="navbar-default"
        aria-expanded="false"
        {...props}
    >
        {children}
    </button>
)

export default function HomeNav() {
    const [isOpenMenu, setIsOpenMenu] = useState(false)

    return (
        <>
            <nav className="top-0 z-20 w-full md:absolute">
                <div className="container mx-auto flex min-h-[64px] max-w-screen-xl flex-row flex-wrap items-center justify-between border-b border-sq-gray-100 bg-white px-4 md:h-[96px] md:border-0 md:bg-transparent md:px-4">
                    <Logo variant="dark" className="h-[23px]" />
                    <SideMenu isOpen={isOpenMenu} onClose={() => setIsOpenMenu(false)} />
                    <BarButton onClick={() => setIsOpenMenu(true)}>
                        <span>Menu</span>
                        <Bars3Icon aria-hidden="true" className="size-8" />
                    </BarButton>
                    <div className="hidden w-full md:block md:w-auto">
                        <div className="mt-4 flex flex-col items-center gap-6 rounded-lg p-4 font-medium md:mt-0 md:flex-row md:border-0 md:p-0">
                            <Link
                                href="/proprietario/anunciar-imovel"
                                className="block text-white"
                                aria-current="page"
                                onClick={trackAnnounceProperty}
                            >
                                Anuncie <span className="hidden lg:inline-block">na SuaQuadra</span>
                            </Link>
                            <Link
                                href="https://blog.suaquadra.com.br/"
                                target="_blank"
                                className="text-white"
                                onClick={trackBlog}
                            >
                                Blog
                            </Link>
                            <Link href="/ajuda" className="text-white" onClick={trackHelp}>
                                Ajuda
                            </Link>
                            <AuthIcon className="rounded-2xl border border-white px-4 py-2 text-white" />
                        </div>
                    </div>
                </div>
            </nav>
            <AuthModal />
        </>
    )
}
