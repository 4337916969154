import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { cn } from '@sq/components'
import { ComponentProps } from 'react'

import SQLinkButton from '@/components/_shared/SQLinkButton/SQLinkButton'
import SQSpinner from '@/components/_shared/SQSpinner/SQSpinner'

type Props = {
    isLoading?: boolean
    isAnimated?: boolean
    onClick: () => void
} & Omit<ComponentProps<typeof SQLinkButton>, 'href' | 'tabIndex' | 'onClick'>

export default function SubmitButton({ isLoading, isAnimated, className, onClick, ...props }: Props) {
    return (
        <SQLinkButton
            href="#"
            className={cn(
                'group flex w-full flex-row items-center justify-center text-sm font-semibold outline-secondary transition-all',
                className
            )}
            onClick={(e) => {
                e.preventDefault()
                onClick()
            }}
            {...props}
        >
            Buscar
            {isLoading ? (
                <SQSpinner svgClasses="size-5" />
            ) : (
                <MagnifyingGlassIcon
                    className={cn(
                        'size-5 fill-secondary transition-transform',
                        isAnimated && 'group-hover:scale-125 group-focus:scale-125'
                    )}
                />
            )}
        </SQLinkButton>
    )
}
