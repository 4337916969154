import { createHighlightComponent } from '@nozbe/microfuzz/react'
import { cn } from '@sq/components'
import { BairroLogradouro, Region } from '@sq/data/schemas'
import { ComponentProps, MouseEventHandler } from 'react'

import { AutoCompleteResult } from '@/components/search/useSearchAutocomplete'

type Props = {
    query: string
    filteredRegions: AutoCompleteResult<Region>[]
    filteredLogradouros: AutoCompleteResult<BairroLogradouro>[]
    fallBackRegions: Region[]
    fallBackLogradouros: BairroLogradouro[]
    onSelectRegion: (result: Region) => void
    onSelectLogradouro: (result: BairroLogradouro) => void
}

const Highlight = createHighlightComponent({}, 'font-bold')

const stopPropagation =
    (callback: MouseEventHandler<HTMLButtonElement> | undefined): MouseEventHandler<HTMLButtonElement> =>
    (e) => {
        e.stopPropagation()
        callback?.(e)
    }

const logradouroToText = (logradouro: BairroLogradouro) => [logradouro.logradouro, logradouro.bairro].join(', ')

function Item({ children, className, onClick, ...props }: ComponentProps<'button'>) {
    return (
        <button
            className={cn('text-left text-sm underline-offset-4 hover:underline', className)}
            onClick={stopPropagation(onClick)}
            {...props}
        >
            {children}
        </button>
    )
}

export default function SearchAutocomplete({
    query,
    filteredRegions,
    fallBackRegions,
    filteredLogradouros,
    fallBackLogradouros,
    onSelectRegion,
    onSelectLogradouro,
}: Props) {
    const isQueryEmpty = query.length === 0
    const isEmptyRegions = !filteredRegions.length
    const isEmptyLogradouros = !filteredLogradouros.length
    const showRegionsFallback = isEmptyRegions || isQueryEmpty
    const showLogradourosFallback = isEmptyLogradouros || isQueryEmpty

    return (
        <div className="divide-y divide-sq-gray-200">
            {isEmptyRegions && isEmptyLogradouros && (
                <div className="flex w-full flex-col py-4">
                    <h3 className="text-lg font-bold text-sq-gray-600">Não encontramos {query}</h3>
                    <p>Mas você pode encontrar seu ponto em algum desses locais:</p>
                </div>
            )}

            {!isEmptyRegions && !isQueryEmpty && (
                <div className="flex w-full flex-col gap-3 py-4 pb-8 md:gap-1">
                    <span className="mb-1 font-bold">Bairros</span>
                    {filteredRegions.map(({ item, highlightRanges }) => (
                        <Item key={`result-${item.bairro_slug}`} onClick={() => onSelectRegion(item)}>
                            <Highlight text={item.bairro} ranges={highlightRanges} />
                        </Item>
                    ))}
                </div>
            )}

            {showRegionsFallback && (
                <div className="flex w-full flex-col gap-3 py-4 pb-8 md:gap-1">
                    <span className="mb-1 font-bold">Bairros mais procurados:</span>
                    {fallBackRegions.map((item) => (
                        <Item key={`result-${item.bairro_slug}`} onClick={() => onSelectRegion(item)}>
                            {item.bairro}
                        </Item>
                    ))}
                </div>
            )}

            {!isEmptyLogradouros && !isQueryEmpty && (
                <div className="flex w-full flex-col gap-3 py-4 pt-8 md:gap-1">
                    <span className="mb-1 font-bold">Ruas</span>
                    {filteredLogradouros.map(({ item, highlightRanges }) => (
                        <Item key={`result-${logradouroToText(item)}`} onClick={() => onSelectLogradouro(item)}>
                            <Highlight text={logradouroToText(item)} ranges={highlightRanges} />
                        </Item>
                    ))}
                </div>
            )}

            {showLogradourosFallback && (
                <div className="flex w-full flex-col gap-3 py-4 pt-8 md:gap-1">
                    <span className="mb-1 font-bold">Ruas mais procuradas:</span>
                    {fallBackLogradouros.map((item) => (
                        <Item key={`result-${item.logradouro}`} onClick={() => onSelectLogradouro(item)}>
                            {logradouroToText(item)}
                        </Item>
                    ))}
                </div>
            )}
        </div>
    )
}
