import { Filter } from '../search.entity'
import { FilterPattern, SerializedFilterPattern } from './types'

const filterPriorities: Record<string, number> = {
    'tipo-de-imovel': 10,
    'antes-era': 9,
    preço: 8,
    'tamanho-do-imovel': 7,
    'fluxo-pedestre': 6,
    'fluxo-veículo': 5,
    densidade: 4,
    Esquina: 3,
    banheiros: 2,
    vagas: 1,
}

export const sortPatterns = [
    { pattern: /barato/, name: 'Menor preço', value: 'preço', sort: 'asc' },
    { pattern: /caro/, name: 'Maior preço', value: 'preço', sort: 'desc' },
    { pattern: /pequeno/, name: 'Menor tamanho', value: 'tamanho-do-imovel', sort: 'asc' },
    { pattern: /grande/, name: 'Maior tamanho', value: 'tamanho-do-imovel', sort: 'desc' },
    { pattern: /recente/, name: 'Mais recentes', value: 'published_at', sort: 'desc' },
    { pattern: /visto/, name: 'Mais vistos', value: 'pageviews', sort: 'desc' },
    { pattern: /relevante/, name: 'Mais relevantes', value: 'relevance-score', sort: 'desc' },
]

export const serializeFilterPatterns = (patterns: FilterPattern[]) => {
    return patterns.map((patternObj) => ({
        ...patternObj,
        pattern: patternObj.pattern.toString(),
    }))
}

export const deserializeFilterPatterns = (patterns: SerializedFilterPattern[]) => {
    return patterns.map((patternObj) => ({
        ...patternObj,
        pattern: new RegExp(patternObj.pattern.slice(1, -1)),
    }))
}

export const processFilterPatternsForClient = (
    filterPatterns: SerializedFilterPattern[] | undefined
): Record<string, Record<string, FilterPattern>> => {
    if (!filterPatterns) return {}
    const deserializedFilterPatterns = deserializeFilterPatterns(filterPatterns)
    const patternLookup = {} as Record<string, Record<string, FilterPattern>>
    deserializedFilterPatterns.forEach((pattern) => {
        if (!patternLookup[pattern.name]) {
            patternLookup[pattern.name] = {}
        }
        patternLookup[pattern.name][pattern.value] = pattern
    })
    return patternLookup
}

export const sortFiltersByPriority = (filters: Filter[]) => {
    const sortedFilters = filters.sort((a, b) => {
        return filterPriorities[b.name] - filterPriorities[a.name]
    })
    return sortedFilters
}
