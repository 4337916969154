import { cn } from '@sq/components'
import { ComponentProps, useCallback, useRef } from 'react'
import { useOnClickOutside } from 'usehooks-ts'

type Props = ComponentProps<'div'> & {
    isVisible: boolean
    onClose: () => void
}

export default function Popup({ isVisible, onClose, className, children, ...props }: Props) {
    const ref = useRef<HTMLDivElement>(null)

    const handleClickOutside = useCallback(() => {
        if (isVisible && onClose) {
            onClose()
        }
    }, [isVisible, onClose])

    useOnClickOutside(ref, handleClickOutside)

    if (!isVisible) {
        return null
    }

    return (
        <div
            ref={ref}
            className={cn(
                'absolute left-0 top-[150%] z-10 flex min-w-[348px] flex-col rounded-2xl bg-white shadow-[0_2px_24px_0_#0000000F] animate-in fade-in',
                className
            )}
            {...props}
        >
            {children}
        </div>
    )
}
