import { BairroLogradouro, Region } from '@sq/data/schemas'

import { FilterPattern, SerializedFilterPattern } from './filters/types'

export const INITIAL_STATE: SearchState = {
    filters: [],
}

export type SearchState = {
    action?: string
    filters?: Filter[]
    regions?: Region[]
    logradouro?: BairroLogradouro
    sort?: Sort
    page?: number
    serializedFilterPatterns?: Record<string, Record<string, FilterPattern>>
}

export type SerializedSearchState = {
    action?: string
    filters?: Filter[]
    regions?: Region[]
    logradouro?: BairroLogradouro
    sort?: Sort
    page?: number
    serializedFilterPatterns?: SerializedFilterPattern[]
}

export type Sort = {
    name: string
    value: string
    sort: string | 'asc' | 'desc'
}

export const SortTypes: Sort[] = [
    {
        name: 'Mais relevantes',
        value: 'relevance-score',
        sort: 'desc',
    },
    {
        name: 'Mais vistos',
        value: 'pageviews',
        sort: 'desc',
    },
    {
        name: 'Mais recentes',
        value: 'published_at',
        sort: 'desc',
    },
    {
        name: 'Menor tamanho',
        value: 'tamanho-do-imovel',
        sort: 'asc',
    },
    {
        name: 'Maior tamanho',
        value: 'tamanho-do-imovel',
        sort: 'desc',
    },
    {
        name: 'Menor preço',
        value: 'preço',
        sort: 'asc',
    },
    {
        name: 'Maior preço',
        value: 'preço',
        sort: 'desc',
    },
]

export type Filter = {
    name: string
    value: string
    is_null?: boolean
    operator?: string
    type: 'int' | 'decimal' | 'string' | 'boolean'
}

type FiltersRegistryType = {
    [key: string]: 'int' | 'decimal' | 'string' | 'boolean'
}

export const FiltersRegistry: FiltersRegistryType = {
    andares: 'int',
    banheiros: 'int',
    condicao: 'int',
    densidade: 'int',
    Esquina: 'string',
    'fluxo-pedestre': 'int',
    'fluxo-veículo': 'int',
    'antes-era': 'string',
    preço: 'decimal',
    quartos: 'int',
    'tamanho-do-imovel': 'decimal',
    'tamanho-da-fachada': 'decimal',
    'tipo-de-imovel': 'string',
    vagas: 'int',
    page: 'int',
}

export const setRegions = (regions: Region[], state: SearchState) => {
    return { ...state, regions }
}

export const setLogradouro = (logradouro: BairroLogradouro | undefined, state: SearchState) => {
    return { ...state, logradouro }
}

export const setSort = (sort: Sort, state: SearchState) => {
    return { ...state, sort }
}

export const addFilters = (filters: Filter[], state: SearchState) => {
    return { ...state, filters: [...(state.filters || []), ...filters] }
}

export const addSingleFilter = (filter: Filter, state: SearchState) => {
    if (state.filters!.filter((f) => f.name === filter.name)[0]?.value === filter?.value) {
        return state
    }

    const newState = removeFilter(filter, state)
    return addFilters([filter], newState)
}

export const removeFilter = (filter: Filter, state: SearchState) => {
    return { ...state, filters: state.filters!.filter((f) => f.name !== filter.name) }
}

export const clearFilters = (state: SearchState) => {
    return { ...state, filters: [] }
}

export const buildUrl = (state: SearchState) => {
    const queryParams = new URLSearchParams(window.location.search)

    // Remove page param, is set by pagination component
    queryParams.delete('page')

    Object.keys(FiltersRegistry).forEach((filterName) => {
        const f = state.filters?.filter((f) => f.name === filterName)[0]
        if (!f) {
            if (queryParams.has(filterName)) {
                queryParams.delete(filterName)
            }
            return
        }

        queryParams.set(f.name, f.value)
    })

    let regionUrl = 'all'
    let streetUrl = ''
    queryParams.delete('q')
    if (state.regions && state.regions.length > 0) {
        regionUrl = state.regions.map((r) => r.bairro_slug).join('_')
    }

    if (state?.logradouro && state.logradouro.logradouro && Object.keys(state.logradouro!).length > 0) {
        streetUrl = state.logradouro.logradouro_slug
    }

    if (state.sort) {
        queryParams.set('sort', `${state.sort.name}_${state.sort.sort}`)
    }

    let returnPath = `/imoveis/aluguel`

    if (streetUrl !== '') {
        if (regionUrl === 'all') {
            regionUrl = state.logradouro?.bairro_slug || 'all'
        }
        returnPath = `${returnPath}/${regionUrl}/${streetUrl}`
    } else {
        returnPath = `${returnPath}/${regionUrl}`
    }
    if (queryParams.toString() !== '') {
        returnPath = `${returnPath}?${queryParams.toString()}`
    }
    return returnPath
}

export const buildFiltersFromQuery = (query: any) => {
    const ALLOWED_KEYS = Object.keys(FiltersRegistry)
    return Object.keys(query)
        .filter((key) => ALLOWED_KEYS.includes(key))
        .map((key) => ({
            name: key,
            value: query[key],
            type: FiltersRegistry[key],
            is_null: false,
            operator: FiltersRegistry[key] === 'string' ? 'in' : '',
        }))
}

const searchEntity = {
    INITIAL_STATE,
    setRegions,
    setSort,
    setLogradouro,
    addFilters,
    addSingleFilter,
    removeFilter,
    clearFilters,
    buildUrl,
    buildFiltersFromQuery,
}

export default searchEntity
