import { cn } from '@sq/components'
import { ComponentProps } from 'react'
import useScrollLock from 'src/hooks/scrolllock'

export default function ModalContainer({
    isVisible,
    children,
    className,
    ...props
}: ComponentProps<'div'> & { isVisible: boolean }) {
    useScrollLock(isVisible)

    if (!isVisible) {
        return null
    }

    return (
        <div
            tabIndex={-1}
            className={cn(
                'fixed left-0 top-0 z-[100] flex size-full flex-col justify-between animate-in fade-in md:hidden',
                className
            )}
            {...props}
        >
            {children}
        </div>
    )
}
