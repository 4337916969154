import { LightBulbIcon } from '@heroicons/react/24/outline'
import artex from '@public/whoTrust/artex.png'
import guaco from '@public/whoTrust/guaco.png'
import kopenhagen from '@public/whoTrust/kopenhagen.png'
import nema from '@public/whoTrust/nema.png'
import semenzato from '@public/whoTrust/semenzato.png'
import theCoffee from '@public/whoTrust/the-coffee-sm.png'
import { captureException } from '@sentry/nextjs'
import { Banner } from '@sq/components'
import { Supply } from '@sq/data/schemas'
import { GetStaticPropsResult } from 'next'
import Head from 'next/head'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect } from 'react'
import { AuthContext } from 'src/context/auth'

import Footer from '@/components/_shared/Footer'
import PreFooter from '@/components/_shared/PreFooter'
import HomeHeader from '@/components/home/HomeHeader'
import HomeNav from '@/components/home/HomeNav'
import SQMedia from '@/components/home/SQMedia'
import BestPlatform from '@/components/home/bestPlatform'
import SupplySection from '@/components/home/sections/SupplySection'
import SupplySubmit from '@/components/home/supplySubmit/SupplySubmit'
import {
    ButtonTrackClickType,
    CardTrackClickType,
    EventType,
    GetButtonEventProperties,
    GetPageViewEventProperties,
    track,
} from '@/components/tracks'
import { Filter } from '@/lib/@core/search/search.entity'
import supplies from '@/lib/@core/supply/supply.gateway'

import styles from '../styles/Home.module.scss'

type PageProps = {
    pageView: Supply[]
    mostRecent: Supply[]
    salaComercial: Supply[]
}

export async function getStaticProps(): Promise<GetStaticPropsResult<PageProps>> {
    const pageViewReq = supplies.search({
        sort: {
            name: 'Mais vistos',
            value: 'pageviews',
            sort: 'desc',
        },
        limit: 10,
        offset: 0,
    })

    const mostRecentReq = supplies.search({
        sort: {
            name: 'Mais recentes',
            value: 'published_at',
            sort: 'desc',
        },
        limit: 10,
        offset: 0,
    })

    const salaComercialFilter: Filter = {
        name: 'tipo-de-imovel',
        value: 'Sala comercial',
        operator: 'in',
        type: 'string',
    }

    const salaComercialReq = supplies.search({
        filters: [salaComercialFilter],
        sort: {
            name: 'Mais vistos',
            value: 'pageviews',
            sort: 'desc',
        },
        limit: 10,
        offset: 0,
    })

    const [pageView, mostRecent, salaComercial] = await Promise.allSettled([
        pageViewReq,
        mostRecentReq,
        salaComercialReq,
    ])

    return {
        props: {
            pageView: pageView.status === 'fulfilled' ? pageView.value.data : [],
            mostRecent: mostRecent.status === 'fulfilled' ? mostRecent.value.data : [],
            salaComercial: salaComercial.status === 'fulfilled' ? salaComercial.value.data : [],
        },
    }
}

export default function Index({ pageView, mostRecent, salaComercial }: PageProps) {
    const title = 'Aluguel de Ponto Comercial em São Paulo - imóveis comerciais | SuaQuadra'
    const description =
        'Lojas, salões e pontos comerciais para alugar em São Paulo. Encontre o imóvel comercial que mais combina com o seu negócio.'
    const { isLoggedIn } = React.useContext(AuthContext)
    const router = useRouter()

    const onRecommendedBannerClick = useCallback(async () => {
        track(
            EventType.ButtonClick,
            GetButtonEventProperties('Clicou no CTA recomendados na home', ButtonTrackClickType.BannerRecommendedHome)
        )

        if (isLoggedIn) {
            router.push('/para-voce')
        } else {
            const currentUrl: URL = new URL('/login?redirectUrl=/para-voce', window.location.href)
            router.push(currentUrl)
        }
    }, [isLoggedIn, router])

    useEffect(() => {
        track(EventType.PageView, GetPageViewEventProperties())
    }, [])

    useEffect(() => {
        if (!pageView || pageView.length === 0) {
            captureException('Empty pageviews on home')
        }

        if (!mostRecent || mostRecent.length === 0) {
            captureException('Empty mostRecent on home')
        }

        if (!salaComercial || salaComercial.length === 0) {
            captureException('Empty salaComercial on home')
        }
    }, [pageView, mostRecent, salaComercial])

    return (
        <>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1" />

                <meta property="og:title" content={title} />
                <meta property="og:image" content="/arts/og-thumb.png" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:description" content={description} />
                <meta name="description" content={description} />
                <title>{title}</title>
                <link rel="canonical" href={`https://www.suaquadra.com.br/`} />
            </Head>
            <div className={styles.Home}>
                <HomeNav />
                <HomeHeader />
                <section className="container mx-auto mb-10 flex justify-center px-4">
                    <Banner
                        icon={<LightBulbIcon className="size-6" />}
                        description="Imóveis recomendados para você"
                        buttonText="Ver recomendações"
                        onClick={onRecommendedBannerClick}
                    />
                </section>
                <div className="container mx-auto max-w-screen-xl pl-4">
                    {pageView?.length > 0 && (
                        <SupplySection
                            titulo={'Os mais populares'}
                            subtitulo={'Pontos comerciais mais visitados'}
                            destaque={'  '}
                            link={'/imoveis/aluguel/all?sort=Mais+vistos_desc'}
                            supplies={pageView}
                            trackOrigin={CardTrackClickType.PropertyCardSectionPageview}
                        />
                    )}
                    {mostRecent?.length > 0 && (
                        <SupplySection
                            titulo={'Acabou de chegar'}
                            subtitulo={'Imóveis comerciais publicados recentemente'}
                            destaque={'  '}
                            link={'/imoveis/aluguel/all?sort=Mais+recentes_desc'}
                            supplies={mostRecent}
                            trackOrigin={CardTrackClickType.PropertyCardSectionNew}
                        />
                    )}
                    {salaComercial?.length > 0 && (
                        <SupplySection
                            titulo={'Perfeitos para escritório'}
                            subtitulo={'Salas comerciais em prédios corporativos ideais para a sua empresa'}
                            destaque={'  '}
                            link={'/imoveis/aluguel/all?tipo-de-imovel=Sala+comercial'}
                            supplies={salaComercial}
                            showLinkToAll
                            trackOrigin={CardTrackClickType.PropertyCardSectionSalaComercial}
                        />
                    )}
                </div>
                <div>
                    <div className={styles.whoTrust}>
                        <Image
                            className="max-h-[48px] max-w-[170px] p-3 md:max-w-[150px] lg:max-w-[170px]"
                            alt="Logo da empresa artex"
                            src={artex}
                        />
                        <Image
                            className="max-h-[48px] max-w-[170px] md:max-w-[150px] lg:max-w-[170px]"
                            alt="Logo da empresa guaco"
                            src={guaco}
                        />
                        <Image
                            className="max-h-[48px] max-w-[170px] md:max-w-[150px] lg:max-w-[170px]"
                            alt="Logo da empresa Kopenhagen"
                            src={kopenhagen}
                        />
                        <Image
                            className="max-h-[48px] max-w-[170px] md:max-w-[150px] lg:max-w-[170px]"
                            alt="Logo da empresa nema"
                            src={nema}
                        />
                        <Image
                            className="max-h-[48px] max-w-[170px] md:max-w-[150px] lg:max-w-[170px]"
                            alt="Logo da empresa semenzato"
                            src={semenzato}
                        />
                        <Image
                            className="max-h-[48px] max-w-[170px] md:max-w-[150px] lg:max-w-[170px]"
                            alt="Logo da empresa the coffee"
                            src={theCoffee}
                        />
                    </div>
                    <BestPlatform />
                </div>
                <SupplySubmit />
                <SQMedia />
            </div>
            <PreFooter />
            <Footer />
        </>
    )
}
