import { ArrowRightIcon } from '@heroicons/react/20/solid'
import { Region, Supply } from '@sq/data/schemas'
import React from 'react'

import SQButton from '@/components/_shared/SQButton/SQButton'
import SQLink from '@/components/_shared/SQLink/SQLink'
import SQSupplyCarousel from '@/components/_shared/SQSupplyCarousel/SQSupplyCarousel'
import {
    ButtonTrackClickType,
    CardTrackClickType,
    EventType,
    GetButtonEventProperties,
    track,
} from '@/components/tracks'

interface Props {
    titulo?: string
    subtitulo?: string
    destaque?: string
    supplies: Supply[]
    link?: string
    showLinkToAll?: boolean
    region?: Region
    trackOrigin?: CardTrackClickType
}

function SupplySection(props: Props) {
    return (
        <>
            <SQSupplyCarousel
                title={props.titulo}
                subTitle={props.subtitulo}
                heading={props.destaque}
                link={props.link}
                supplies={props.supplies}
                trackOrigin={props.trackOrigin}
            />
            {props.showLinkToAll && (
                <SQButton
                    size="small"
                    className="pl-0"
                    variant="secondary"
                    onClick={() =>
                        track(
                            EventType.ButtonClick,
                            GetButtonEventProperties(
                                props.region ? `Ver mais imóveis em: ${props.region.bairro}` : 'Ver todos os imóveis',
                                ButtonTrackClickType.SearchProperty
                            )
                        )
                    }
                >
                    {props.region ? (
                        <SQLink withoutStyles className="flex" href={`/imoveis/aluguel/${props.region.bairro_slug}`}>
                            Ver mais pontos comerciais em {props.region.bairro}
                            <ArrowRightIcon style={{ height: '16px', marginLeft: '8px' }} />
                        </SQLink>
                    ) : (
                        <SQLink withoutStyles className="flex" href="/imoveis/aluguel/all">
                            Ver todos os pontos comerciais
                            <ArrowRightIcon style={{ height: '16px', marginLeft: '8px' }} />
                        </SQLink>
                    )}
                </SQButton>
            )}
        </>
    )
}

export default SupplySection
