import Image from 'next/image'
import abfPortalIcon from 'public/SQMedia/abfPortalIcon.png'
import blombergIcon from 'public/SQMedia/blombergIcon.png'
import exameIcon from 'public/SQMedia/exameIcon.png'
import pegnIcon from 'public/SQMedia/pegnIcon.png'
import terraIcon from 'public/SQMedia/terraIcon-sm.png'
import React from 'react'

import SQLink from '@/components/_shared/SQLink/SQLink'
import { ButtonTrackClickType, EventType, GetButtonEventProperties, track } from '@/components/tracks'

import styles from './SQMedia.module.scss'

export default function SQMedia() {
    return (
        <div className={styles.SQMedia}>
            <div className={styles.wrapper}>
                <div className={styles.title}>
                    <h1>SuaQuadra saiu na mídia</h1>
                </div>
                <div className={styles.mediaContainer}>
                    <div className={styles.media}>
                        <div className={styles.mediaImage}>
                            <SQLink
                                href="https://exame.com/negocios/nao-consegue-encontrar-o-ponto-comercial-essa-startup-captou-r-21-milhoes-para-ajudar-nesta-missao/"
                                target="_blank"
                                onClick={() => {
                                    track(
                                        EventType.ButtonClick,
                                        GetButtonEventProperties('SQMedia - Exame', ButtonTrackClickType.SQMediaExame)
                                    )
                                }}
                            >
                                <Image
                                    src={exameIcon}
                                    alt="Exame icon"
                                    className={styles.mediaImage}
                                    width="0"
                                    height="0"
                                    sizes="100vw"
                                />
                            </SQLink>
                        </div>
                    </div>
                    <div className={styles.media}>
                        <div className={styles.mediaImage}>
                            <SQLink
                                href="https://revistapegn.globo.com/startups/noticia/2023/06/suaquadra-capta-seed-de-r-21-milhoes.ghtml"
                                target="_blank"
                                onClick={() => {
                                    track(
                                        EventType.ButtonClick,
                                        GetButtonEventProperties('SQMedia - PEGN', ButtonTrackClickType.SQMediaPEGN)
                                    )
                                }}
                            >
                                <Image
                                    src={pegnIcon}
                                    alt="PEGN icon"
                                    className={styles.mediaImage}
                                    width="0"
                                    height="0"
                                    sizes="100vw"
                                />
                            </SQLink>
                        </div>
                    </div>
                    <div className={styles.media}>
                        <div className={styles.mediaImage}>
                            <SQLink
                                href="https://www.bloomberglinea.com.br/2023/07/01/rodadas-da-semana-startup-de-credito-consignado-capta-r-110-milhoes/"
                                target="_blank"
                                onClick={() => {
                                    track(
                                        EventType.ButtonClick,
                                        GetButtonEventProperties(
                                            'SQMedia - Blomberg',
                                            ButtonTrackClickType.SQMediaBlomberg
                                        )
                                    )
                                }}
                            >
                                <Image
                                    src={blombergIcon}
                                    alt="blomberg icon"
                                    className={styles.mediaImage}
                                    width="0"
                                    height="0"
                                    sizes="100vw"
                                />
                            </SQLink>
                        </div>
                    </div>
                    <div className={styles.media}>
                        <div className={styles.mediaImage}>
                            <SQLink
                                href="https://www.terra.com.br/economia/dinheiro-em-dia/meu-negocio/suaquadra-levanta-seed-de-r-21-mi-com-a-kaszek,c99485c057af3c1aa4c1c5385e462d79ubjeilqy.html"
                                target="_blank"
                                onClick={() => {
                                    track(
                                        EventType.ButtonClick,
                                        GetButtonEventProperties('SQMedia - Terra', ButtonTrackClickType.SQMediaTerra)
                                    )
                                }}
                            >
                                <Image
                                    src={terraIcon}
                                    alt="Portal Terra icon"
                                    className={styles.mediaImage}
                                    width="0"
                                    height="0"
                                    sizes="100vw"
                                />
                            </SQLink>
                        </div>
                    </div>
                    <div className={styles.media}>
                        <SQLink
                            href="https://www.portaldofranchising.com.br/franquias/suaquadra-lanca-plataforma-para-a-sua-franquia/"
                            target="_blank"
                            onClick={() => {
                                track(
                                    EventType.ButtonClick,
                                    GetButtonEventProperties('SQMedia - ABF', ButtonTrackClickType.SQMediaABF)
                                )
                            }}
                        >
                            <Image
                                src={abfPortalIcon}
                                alt="ABF portal do franchising icon"
                                className={styles.mediaImage}
                                width="0"
                                height="0"
                                sizes="100vw"
                            />
                        </SQLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
