import { Region, Supply } from '@sq/data/schemas'
import { ROWS_PER_PAGE } from 'src/context/search'

import { hit } from '@/components/tracks/shorts'
import { Filter, Sort } from '@/lib/@core/search/search.entity'
import supplyHttp from '@/lib/http/supply.http'

import { searchToEvent } from '../search/search.utils'

export type SupplyQuery = {
    regions?: Region[]
    filters?: Filter[]
    sort?: Sort | null
    bounds?: Bounds
    limit?: number
    offset?: number
    address?: string
}

export type NearbyQuery = {
    center: {
        lat: number
        lng: number
    }
    filters?: Filter[]
    sort?: Sort
    limit?: number
    offset?: number
    nearby_limit: number
    deny_bairros: Region[]
    deny_logradouro: string
}

export type Bounds = {
    max_lat: number
    max_lng: number
    min_lat: number
    min_lng: number
}

export type Similar = {
    supply?: Supply
    criteria?: any
}

export type SupplyResponse = {
    data: Supply[]
    dataNear: Supply[]
    total: number
    totalNear: number
}

export const NEARBY_CRITERIA = (supply: Supply) => {
    if (!supply.details['preço'] || !supply.details['tipo-de-imovel'] || !supply.details['tamanho-do-imovel']) {
        return {}
    }

    return {
        ranking: [
            {
                name: 'distance',
                weight: 50000,
                value: `${supply.address.location.lat},${supply.address.location.lng}`,
            },
            {
                name: 'preço',
                weight: 3,
                value: `${supply.details['preço'].value}`,
            },
            {
                name: 'tamanho-do-imovel',
                weight: 45,
                value: `${supply.details['tamanho-do-imovel'].value}`,
            },
        ],
        limit: 16,
    }
}
export const SIMILAR_CRITERIA = (supply: Supply) => {
    if (!supply.details['preço'] || !supply.details['tipo-de-imovel'] || !supply.details['tamanho-do-imovel']) {
        return {}
    }

    return {
        restrictions: [
            {
                name: 'tipo-de-imovel',
                value: supply.details['tipo-de-imovel'] ? supply.details['tipo-de-imovel'].value : '',
            },
        ],
        ranking: [
            {
                name: 'distance',
                weight: 10000,
                value: `${supply.address.location.lat},${supply.address.location.lng}`,
            },
            {
                name: 'preço',
                weight: 3,
                value: `${supply.details['preço'].value}`,
            },
            {
                name: 'tamanho-do-imovel',
                weight: 45,
                value: `${supply.details['tamanho-do-imovel'].value}`,
            },
        ],
        limit: 16,
    }
}
const getTypeValue = (range: string, type: Filter['type']) => {
    if (!range.includes('_')) {
        return range
    }

    if (type === 'string') {
        return range
    }

    let [min, max] = range.split('_')

    if (type === 'int') {
        return `${min ? min : ''}-${max ? max : ''}`
    }
    if (type === 'decimal') {
        return `${min ? `${min}00` : ``}-${max ? `${max}00` : ``}`
    }

    return range
}

async function search(payload: SupplyQuery): Promise<SupplyResponse> {
    const { regions, filters, sort, bounds, limit, offset, address } = payload
    let center = null
    if (regions && regions.length > 0) {
        center = {
            lat: regions[0].center_lat,
            lng: regions[0].center_lng,
        }
    }

    const apiFilters = [
        ...(filters?.map((f) => {
            if (f.value === '') {
                return null
            } else {
                return {
                    detail_name: f.name,
                    value: getTypeValue(f.value, f.type),
                    is_null: f.is_null,
                    operator: f.operator,
                }
            }
        }) || []),
    ]

    const query = {
        params: { endpoint: 'api/v2/supply/query' },
        query: {
            bairros: regions && regions?.length > 0 ? regions.map((item) => item.bairro_slug) : undefined,
            filters: apiFilters,
            sort: sort
                ? {
                      detail_name: sort?.value,
                      sort: sort?.sort,
                  }
                : // default sort
                  {
                      detail_name: 'relevance-score',
                      sort: 'desc',
                  },
            limit: limit || ROWS_PER_PAGE,
            offset: offset || 0,
            bounds,
            address,
            expanded: !!center,
            center: center,
        },
    }

    const res = await supplyHttp.post<any, SupplyResponse>(query.params.endpoint, query.query)

    // TODO: Move to usecase, and remove these awaits....
    hit(searchToEvent(payload, res))
    return res
}

async function nearby({
    filters,
    sort,
    limit,
    offset,
    center,
    nearby_limit,
    deny_bairros,
    deny_logradouro,
}: NearbyQuery): Promise<SupplyResponse> {
    const apiFilters = [
        ...(filters?.map((f) => {
            if (f.value === '') {
                return null
            } else {
                return {
                    detail_name: f.name,
                    value: getTypeValue(f.value, f.type),
                    is_null: f.is_null,
                    operator: f.operator,
                }
            }
        }) || []),
    ]

    const query = {
        params: { endpoint: 'api/v2/supply/nearby' },
        query: {
            filters: apiFilters,
            sort: sort
                ? {
                      detail_name: sort?.value,
                      sort: sort?.sort,
                  }
                : // default sort
                  {
                      detail_name: 'relevance-score',
                      sort: 'desc',
                  },
            limit: limit || ROWS_PER_PAGE,
            center,
            offset,
            nearby_limit,
            deny_bairros: deny_bairros && deny_bairros?.length > 0 ? deny_bairros.map((item) => item.bairro_slug) : [],
            deny_logradouro,
        },
    }

    return supplyHttp.post<any, SupplyResponse>('api/v2/supply/nearby', query.query)
}

const SIMILAR_DEFAULT_CRITERIA = (supply: Supply) => {
    if (!supply.details['tipo-de-imovel'] || !supply.details['tamanho-do-imovel']) {
        return {}
    }

    return {
        restrictions: [
            {
                name: 'tipo-de-imovel',
                value: supply.details['tipo-de-imovel'] ? supply.details['tipo-de-imovel'].value : '',
            },
        ],
        ranking: [
            {
                name: 'distance',
                weight: 1000,
                value: `${supply.address.location.lat},${supply.address.location.lng}`,
            },
            {
                name: 'tamanho-do-imovel',
                weight: 100,
                value: `${supply.details['tamanho-do-imovel'].value}`,
            },
        ],
        limit: 16,
    }
}

async function similar(similar: Similar): Promise<SupplyResponse> {
    const query = {
        params: { endpoint: 'api/v2/supply/similar' },
        query: similar.criteria || (similar.supply ? SIMILAR_DEFAULT_CRITERIA(similar.supply) : null),
    }

    if (similar.supply) {
        query.params.endpoint += `/${similar.supply.id}`
    }

    return supplyHttp.post<Supply | null, SupplyResponse>(query.params.endpoint, query.query)
}

function getIds(ids: string[]) {
    return supplyHttp.post<{ ids: string[] }, SupplyResponse>('api/v1/supply/ids', { ids })
}

function get(slug: string) {
    return supplyHttp.get<Supply>(`api/v1/supply/${slug}`)
}

function getById(id: string) {
    return supplyHttp.get<Supply>(`api/v1/supply/show/${id}`)
}

async function amplitudeRecommendation(userId: string, deviceId: string, recommendationId: string) {
    return supplyHttp.get<SupplyResponse>(
        `api/v1/recommendation/user?user_id=${userId}&device_id=${deviceId}&recommendation_id=${recommendationId}`
    )
}

const supplyGateway = {
    search,
    nearby,
    similar,
    getIds,
    get,
    getById,
    amplitudeRecommendation,
}

export default supplyGateway
